<template>
	<div style="height: 100%;">
		<main-table>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input v-model="params.keyword" placeholder="名称/手机/邮箱" clearable></el-input>
				</el-form-item>
				<el-form-item v-if="!admin_user.shop_id">
					<el-select v-model="params.shop_id" placeholder="所属门店" filterable clearable>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.role" placeholder="系统角色" filterable clearable>
						<el-option v-for="role in roles" :key="role.id" :label="admin_user.shop_id ? role.name : `${role.name}@${role.shop ? role.shop.name : theme.group}`" :value="role.id">
							<span style="float: left">{{ role.name }}</span>
							<span style="float: right; color: #8492a6; font-size: 13px" v-if="!admin_user.shop_id">{{ role.shop ? role.shop.name : theme.group }}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getUsers({ ...params, page: 1})">查询</el-button>
					<el-button type="primary" @click="$refs['show_user'].open()" :disabled="!$utils.create('users')">添加用户</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" ref="userTable" class="scroll-wrapper absolute" height="100%" :data="users" :size="theme.size">
				<el-table-column label="ID" prop="id" width="48"></el-table-column>
				<el-table-column label="名称" prop="name" min-width="100">
					<el-link slot-scope="scope" type="primary" @click="$refs['show_user'].open(scope.row)">{{scope.row.name}}</el-link>
				</el-table-column>
				<el-table-column label="手机号码" prop="mobile" min-width="100"></el-table-column>
				<el-table-column label="电子邮箱" prop="email" min-width="140"></el-table-column>
				<el-table-column label="系统角色" min-width="240">
					<template slot-scope="scope">
						<el-tooltip class="roles" effect="dark" placement="top-start" v-for="role in scope.row.roles" :key="role.id" :content="role.description" :disabled="!role.description">
							<el-tag size="mini" :type="role.id==params.role?'success':''">{{role.name}}<template v-if="!admin_user.shop_id">@{{role.shop ? role.shop.name : theme.group}}</template></el-tag>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column prop="updated_at" label="更新时间" min-width="150"></el-table-column>
				<el-table-column prop="created_at" label="创建时间" min-width="150"></el-table-column>
				<!-- <el-table-column fixed="right" label="更多操作" width="100">
					<template slot-scope="scope">
						<el-button @click="handleRecharge(scope.row)" v-if="admin_user.shop_id" type="text" size="mini">余额</el-button>
						<el-button @click="handleEdit(scope.row)" type="text" size="mini" :disabled="!$utils.update('users')">编辑</el-button>
					</template>
				</el-table-column> -->
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getUsers({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getUsers({ ...params, page: v }) }"
				background>
			</el-pagination>
		</main-table>
		<!-- 用户充值 -->
		<el-dialog width="480px" title="用户余额" :visible.sync="recharge_dialog" @closed="resetRechargeForm('rechargeForm')">
			<el-form status-icon :model="rechargeForm" :size="theme.size" ref="rechargeForm" label-width="80px">
				<el-form-item label="用户名称" prop="name" required>
					<el-input v-model="rechargeForm.name" autocomplete="off" readonly></el-input>
				</el-form-item>
				<el-form-item label="钱包余额" prop="wallet" required>
					<el-input v-model="rechargeForm.wallet" autocomplete="off" readonly>
						<template slot="append">分</template>
					</el-input>
				</el-form-item>
				<el-form-item label="变动类型" prop="type" required>
					<el-radio-group v-model="rechargeForm.type">
						<el-radio label="recharge">充值</el-radio>
						<el-radio label="expense">扣除</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="变动金额" prop="price" required>
					<el-input v-model="rechargeForm.price" autocomplete="off" placeholder="请输入充值金额">
						<template slot="append">分</template>
					</el-input>
				</el-form-item>
				<el-form-item label="变动说明" prop="desc">
					<el-input v-model="rechargeForm.desc" autocomplete="off" placeholder="请输入充值说明">
						<template slot="append">分</template>
					</el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetRechargeForm('rechargeForm')">取 消</el-button>
				<el-button type="primary" @click="submitRechargeForm('rechargeForm')">确 定</el-button>
			</div>
		</el-dialog>
		<show-user ref="show_user" :roles="roles" @refresh="getUsers(params)"></show-user>
	</div>
</template>

<style>
	.roles { margin: 0 8px 8px 0; }
</style>

<script>
	import { mapState } from 'vuex';
	import showUser from './show.vue';
	import mainTable from '@/components/main-table';
	
	export default {
		components: {
			mainTable,
			showUser
		},
		computed: {
			...mapState(['theme', 'admin_user'])
		},
		methods: {
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error('提交表单有误！');
					if (!this.$utils.checkAuth('users', 'roles')) {
						delete this.user.roles;
					}
					this.submitUser(this.user);
				});
			},
			async submitUser (data) {
				const res = await this.$http.post(this.$api.URI_USERS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						if (this.user_drawer) {
							this.user_drawer = false;
							this.getUsers(this.params);
						}
					}
				});
			},
			submitRechargeForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error('提交表单有误！');
					const res = await this.$http.post(this.$api.URI_WALLETS, this.rechargeForm);
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.recharge_dialog = false;
							this.getUsers(this.params);
						}
					});
				});
			},
			resetRechargeForm (ref) {
				this.recharge_dialog = false;
				this.$refs[ref].resetFields();
			},
			handleEdit (r) {
				if (r.work_wechat_user) {
					r.work_wechat_user_id = r.work_wechat_user.id;
				}
				this.user = {...r, roles: r.roles.map((r) => r.id) };
				this.user_drawer = true;
			},
			handleRecharge (r) {
				this.recharge_dialog = true;
				this.rechargeForm = {...this.rechargeForm, user_id: r.id}
			},
			async getUsers (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_USERS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.users = result.data;
				this.shops = result.shops;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			async getRoles (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_ROLES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.roles = result.data.filter((r) => !r.deleted_at);
			}
		},
		data () {
			return {
				user: {},
				users: [],
				roles: [],
				shops: [],
				user_drawer: false,
				recharge_dialog: false,
				rechargeForm: {
					type: 'recharge'
				},
				params: {
					perPage: 10
				},
				rules: {
					name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }]
				}
			}
		},
		created () {
			this.getUsers(this.params, true);
			this.getRoles({perPage: 50}, true);
		}
	};
</script>